import React, { createContext, useContext, useEffect, useReducer } from 'react'

import PropTypes from 'prop-types'

import app from 'gatsby-plugin-firebase-v9.0'
import { getAuth } from 'firebase/auth'
import { getDatabase, ref, onValue, off } from 'firebase/database'
import { getUser, isBrowser, logout } from '../utils/auth'
import { fetch, GET_USER_PROFILE } from '../hooks/useHasura'

const defaultState = {
  state: {
    isMenuOpen: false,
    user: null,
  },
  setIsMenuOpen: () => {},
  setUser: () => {},
}

const AppContext = createContext(defaultState)

const SET_USER = 'SET_USER'
const SET_MENU_OPEN = 'SET_MENU_OPEN'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_OPEN:
      return {
        ...state,
        state: {
          ...state.state,
          isMenuOpen: action.payload,
        },
      }
    case SET_USER:
      if (isBrowser()) {
        // eslint-disable-next-line no-undef
        window.localStorage.setItem('user', JSON.stringify(action.payload))
      }
      return {
        ...state,
        state: {
          ...state.state,
          user: action.payload,
        },
      }
    default:
      return state
  }
}

let callback = null
let metadataRef = null

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  const contextProviderValue = {
    ...state,
    setIsMenuOpen: payload =>
      dispatch({
        type: SET_MENU_OPEN,
        payload,
      }),
    setUser: payload =>
      dispatch({
        type: SET_USER,
        payload,
      }),
  }

  // Refresh token to persist connection.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      contextProviderValue.setUser(getUser())
      const firebaseAuth = getAuth(app)
      firebaseAuth.onAuthStateChanged(async user => {
        if (user) {
          // User is signed in.
          // Remove previous listener.
          if (callback) {
            off(metadataRef, callback)
          }
          // On user login add new listener.
          if (user) {
            // Check if refresh is required.
            const firebaseDatabase = getDatabase(app)

            metadataRef = ref(firebaseDatabase, `metadata/${user.uid}/refreshTime`)

            callback = async () => {
              // Force refresh to pick up the latest custom claims changes.
              // Note this is always triggered on first call. Further optimization could be
              // added to avoid the initial trigger when the token is issued and already contains
              // the latest claims.
              const token = await user.getIdToken(true)

              const firebaseUser = {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                token,
                avatar: user.providerData?.[0]?.photoURL, // Google and FB images
              }
              // ! setUser before fetch is important.
              contextProviderValue.setUser(firebaseUser)
              const result = await fetch({
                query: GET_USER_PROFILE,
                variables: {
                  user_uid: user.uid,
                },
              })
              const hasuraUser = result?.data?.user?.[0]
              if (!hasuraUser) console.error('User not found on hasura')
              contextProviderValue.setUser({
                ...(hasuraUser || {}),
                ...firebaseUser,
                avatar: hasuraUser?.picture?.src || firebaseUser.avatar || null,
                loadedHasura: true,
              })
            }

            // Subscribe new listener to changes on that node.
            onValue(metadataRef, callback)
          }
        } else {
          // No user is signed in.
          logout(app)
          contextProviderValue.setUser({})
        }
      })
    }
  }, [])

  return <AppContext.Provider value={contextProviderValue}>{children}</AppContext.Provider>
}

AppProvider.defaultProps = {}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppProvider }

export default () => useContext(AppContext)
