/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

import React, { useEffect } from "react"

import useAppContext, { AppProvider } from "./src/contexts/AppContext"

import "firebase/auth"
import "firebase/database"
import "./src/styles/styles.scss"

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => (
  <AppProvider>
   {element}
  </AppProvider>
)

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // eslint-disable-next-line
  // todo ici
  // if (!(`IntersectionObserver` in window)) {
  //   import(`intersection-observer`)
  // }
}

// eslint-disable-next-line react/prop-types
const LocationListener = ({ children, location }) => {
  const appContext = useAppContext()

  useEffect(() => {
    appContext.setIsMenuOpen(false)

    /* eslint-disable no-undef */
    if (typeof document !== "undefined" && document)
      document.querySelector("body").scrollTo(0, 0)
    /* eslint-enable no-undef */
  }, [location])
  return <>{children}</>
}

// eslint-disable-next-line
export const wrapPageElement = ({ element, props: { location } }) => (
  <LocationListener location={location}>{element}</LocationListener>
)
