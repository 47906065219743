/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react'
import { createApolloFetch } from 'apollo-fetch'
import { getUser } from '../utils/auth'

const fetch = createApolloFetch({
  uri: process.env.GATSBY_HASURA_GRAPHQL_URL,
})

fetch.use(({ options }, next) => {
  if (!options.headers) {
    options.headers = {} // Create the headers object if needed.
  }
  const user = getUser()
  // eslint-disable-next-line dot-notation
  if (user?.token) options.headers['authorization'] = `Bearer ${user.token}`

  next()
})

export const CAMPAIGN_QUERY = `
query getCampaigns {
  campaign(
    where: {
      status: {
        _in: [CAMPAIGN_STATUS_IN_PROGRESS, CAMPAIGN_STATUS_FORTHCOMING, CAMPAIGN_STATUS_COMPLETED, CAMPAIGN_STATUS_UNCOMPLETED]
      }
    }
    order_by: { created_date: desc }
    ) {
    id
    goal
    status
    end_date
    donations_aggregate {
      aggregate {
        sum {
          amount
        }
        count
      }
    }
  }
}
`

export const CAMPAIGN_TITLE_QUERY = `
query getCampaigns($includeCampaign: Int) {
  campaign(
    where: {
      _or: [
        {
          id: {
            _eq: $includeCampaign,
          }
        },
        {
          status: {
            _in: [CAMPAIGN_STATUS_IN_PROGRESS],
          }
        }
      ]
    }
    order_by: { status: desc, end_date: desc }
    ) {
    id
    title
    end_date
  }
}
`

export const PROJECT_QUERY = `
query getProject {
  project {
    establishment
    contact_identity
    contact_tel
    contact_email
    amount_project
    project_name
    description
    id
    creation_date
  }
}
`

export const FUNDRAISERS_CAMPAIGN_BY_CAMPAIGN_ID_QUERY = `
query getFundraisersCampaignByCampaignId($campaign_id: Int!) {
  fundraisers_campaign(
    where: {
      _and: {
        campaign_id: { _eq: $campaign_id },
        end_date: { _gte: "now" }
      }
    } 
  ) {
    id
    amount
    title
    description
    campaign_id
    end_date
    key
    campaign {
      status
    }
    user_public {
      firstname
      lastname
      picture {
        src
      }
    } 
    donations_aggregate {
      aggregate {
        sum {
          amount
        }
        count
      }
    }
  }
}
`

export const FUNDRAISERS_CAMPAIGN_BY_ID_QUERY = `
query getFundraisersCampaignById($id: Int!) {
  fundraisers_campaign_by_pk (id: $id) {
    amount
    description
    title
    id
    campaign_id
    end_date
    key
  }
}
`

export const FUNDRAISERS_CAMPAIGN_BY_USER_QUERY = `
query getFundraisersCampaignByUser($user_uid: String!) {
  fundraisers_campaign(where: {user: {uid: {_eq: $user_uid}}}) {
    amount
    description
    title
    id
    campaign_id
    end_date
  }
}
`

export const UPDATE_FUNDRAISERS_CAMPAIGN_BY_PK_MUTATION = `
mutation updateFundraisersCampaignByPk(
  $id: Int!,
  $title: String!,
  $description: String!,
  $amount: Int!,
  $end_date: timestamptz!,
  $campaign_id: Int!
) {
    update_fundraisers_campaign_by_pk(
      pk_columns: {
        id: $id
      },
      _set: {
        title: $title,
        description: $description,
        amount: $amount,
        end_date: $end_date,
        campaign_id: $campaign_id
      }
    ) {
     id
    }
  }
`

export const CREATE_FUNDRAISERS_CAMPAIGN_MUTATION = `
mutation createFundraisersCampaign(
    $amount: Int!,
    $campaign_id: Int!,
    $title: String!,
    $description: String!,
    $end_date: timestamptz!,
    $user_uid: String!,
    $key: String!
  ) {
  insert_fundraisers_campaign_one(object: {
    amount: $amount,
    campaign_id: $campaign_id,
    title: $title,
    description: $description,
    end_date: $end_date,
    user_uid: $user_uid,
    key: $key
  }) {
    id
  }
}
`

export const GET_USER_PROFILE = `
  query getUserProfile($user_uid: String!) {
    user(where: {uid: {_eq: $user_uid}}) {
      firstname
      lastname
      email
      phone
      picture {
        src
        alt
      }
    }
  }
`

export const UPDATE_USER_PROFILE = `
  mutation updateUserProfile($user_uid: String!, $email: String!, $firstname: String!, $lastname: String!, $phone: String!) {
    update_user(
      where: { uid: { _eq: $user_uid } },
      _set: {email: $email, firstname: $firstname, lastname: $lastname, phone: $phone}
    ) {
      affected_rows
    }
  }
`

export const INSERT_NEW_PROJECT = `
mutation InsertProject(
  $contact_email: String
  $amount_project: numeric
  $contact_identity: name
  $contact_tel: String
  $description: String
  $establishment: String
  $project_name: String
) {
  insert_project_one(
    object: {
      amount_project: $amount_project
      contact_email: $contact_email
      contact_identity: $contact_identity
      contact_tel: $contact_tel
      description: $description
      establishment: $establishment
      project_name: $project_name
    }
  ) {
    id
  }
}
`

export const UPLOAD_USER_AVATAR = `
  mutation uploadUserAvatar($base64image: String!, $mimeType: String!, $extension: String!) {
    uploadFile(base64image: $base64image, mimeType: $mimeType, extension: $extension) {
      url
    }
  }
`

const useHasura = (query, { variables, skip = false } = { variables: {}, skip: false }) => {
  const [isLoading, setLoading] = useState(!skip)
  const [data, setData] = useState(null)
  const [isRetrying, setIsRetrying] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function cb(counter = 0) {
      setLoading(true)
      try {
        const result = await fetch({
          query,
          variables,
        })
        if (result?.errors?.[0]) throw result.errors[0]
        setData(result.data)
        setLoading(false)
        setError(null)
      } catch (err) {
        if (err?.extensions?.code === 'invalid-jwt') {
          // retry
          if (counter < 5) {
            setIsRetrying(true)
            setTimeout(() => {
              return cb(counter + 1)
            }, 500)
          } else {
            setIsRetrying(false)
          }
        }
        setData(null)
        setLoading(false)
        setError(err)
      }
    }

    if (!skip) cb()
  }, [skip])
  return { isLoading, isRetrying, data, error }
}

export { fetch }
export default useHasura
