import { getAuth } from "firebase/auth"

/* eslint-disable no-undef */
export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user') || {})
    : {}

export const setUser = user =>
  isBrowser() && window.localStorage.setItem('user', JSON.stringify(user))

export const isLoggedIn = () => {
  const user = getUser()
  return !!user?.email
}

export const hasLoadedHasuraUser = () => {
  const user = getUser()
  return user?.loadedHasura
}

export const logout = firebaseApp => {
  return new Promise(resolve => {
    getAuth(firebaseApp)
      .signOut()
      .then(() => {
        setUser({})
        resolve()
      })
  })
}
